body {
  background-color: var(--colorWhiteAlt);
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  font-family: var(--fontBody);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

body * {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  width: 100%;
}

.container {
  margin: 0 auto;
  max-width: var(--layoutContainer);
  width: 90%;
}

.fade-enter {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.fade-enter-active {
  opacity: 1;
}

.fade-enter-done {
}
