.block {
  max-width: 500px;
  width: 100%;
}

.blocklg {
  max-width: 760px;
}

.blockCentered {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.head {
  align-items: center;
  color: var(--colorBlue);
  display: flex;
  margin-bottom: var(--layoutGutter);
}

.icon {
  font-size: 24px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  margin-left: var(--layoutGutterSm);
}

.text {
  line-height: 1.4;
}

.text span {
  display: block;
}

.text span + span {
  margin-top: var(--layoutGutter);
}


.blockCentered .text {
  text-align: center;
}

@media screen and (max-width: 768px) {

}
