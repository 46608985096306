@keyframes pulsating-wave {
  0% {
    opacity: .5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .5;
  }
}

.loader {
  align-items: center;
  background-color: var(--colorWhiteAlt);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  transition: opacity .7s ease-in-out;
  z-index: 1;
}

.onExit {
  opacity: 0;
}

.onExit .icon {
  animation: none;
  opacity: 0;
}

.icon {
  color: var(--colorGrayLight);
  font-size: 50px;
  animation: pulsating-wave 1.5s ease-in-out infinite;
  transition: opacity .7s ease-in-out;
}
