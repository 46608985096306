:root {
  /* Colors */
  --colorWhite: #fff;
  --colorWhiteAlt: #f3f5f9;
  --colorGrayLight: #c4cAd5;
  --colorBlue: #5f8cf7;
  --colorBlueDark: #363449;

  /* Fonts */
  --fontBody: 'Nunito', sans-serif;

  /* Layout */
  --layoutContainer: 1200px;
  --layoutGutterXs: 5px;
  --layoutGutterSm: 10px;
  --layoutGutter: 20px;
  --layoutGutterLg: 40px;
  --layoutGutterXl: 60px;
  --layoutGutterXxl: 180px;
}
