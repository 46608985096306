.container {
  --size: 70px;
  align-items: center;
  background-color: var(--colorWhite);
  border-radius: 30%;
  display: flex;
  height: var(--size);
  justify-content: center;
  width: var(--size);
}

.logo {
  max-width: 70%;
}


@media only screen and (max-width: 768px) {
  .container {
    --size: 40px;
  }
}
