.subscribe {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.form {
  margin-top: var(--layoutGutterLg);
  position: relative;
}

.formRow {
  display: flex;
  margin: 0 -10px;
}

.formRow + .formRow {
  margin-top: var(--layoutGutter);
}

.formSuccess {
  align-items: center;
  background-color: var(--colorWhiteAlt);
  display: flex;
  flex-direction: column;
  left: 0;
  height: 100%;
  justify-content: center;
  /* opacity: .8; */
  position: absolute;
  top: 0;
  width: 100%;
}

.formSuccessTitle {
  font-size: 24px;
  font-weight: 700;
}

.formSuccessText {
  margin-top: var(--layoutGutterSm);
}
