.landing {
  height: 100vh;
  overflow: hidden;
}

.landing.pageLoaded {
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.container {
  position: relative;
}

.intro {
  padding-top: 70px;
  position: relative;
}

.introText {
  font-size: 36px;
  font-weight: 300;
  width: 60%;
  line-height: 1.4;
  padding-right: var(--layoutGutterLg);
}


/* Activities */
.activitiesMockup {
  max-width: 380px;
  opacity: 0;
  position: absolute;
  right: 3%;
  top: -30%;
  transform: translateY(50px);
  transition: opacity .7s ease-in-out 1s, transform .7s ease-in-out 1s;
  width: 40%;
  z-index: 1;
}

.pageLoaded .activitiesMockup {
  opacity: 1;
  transform: translateY(0);
}



.activitiesMockup img {
  max-width: 100%;
}

.activities {
  margin-top: var(--layoutGutterXl);
  width: 60%;
}

.activitiesBlock {
  padding-right: var(--layoutGutterLg);
  width: 100%;
}

/* Tribe */
.tribe {
  display: flex;
  justify-content: space-between;
  margin-top: var(--layoutGutterXxl);
}

.tribeBlock {
  padding-top: var(--layoutGutterXl);
  position: relative;
  width: 40%;
}

.tribeMockupWrapper {
  position: relative;
  width: 55%;
}

.tribeMockup {
  position: absolute;
  top: -20%;
  right: 0;
  width: 100%;
}

.tribeMockup img {
  max-width: 100%;
}

/* Subscribe */
.subscribe {
  padding-top: var(--layoutGutterXl);
  margin-top: var(--layoutGutterXxl);
}

/* Media */
@media screen and (max-width: 1200px) {
  .activitiesMockup {
    top: 0;
    width: 30%;
  }

  .tribeMockup {
    top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .subscribe {
    margin-top: var(--layoutGutterXl);
  }

  .activitiesMockup {
    top: 5%;
  }

  .tribe {
    margin-top: var(--layoutGutterXl);
  }

  .tribeMockup {
    top: 20%;
    /* transform: translateY(-50%); */
  }
}

@media screen and (max-width: 768px) {
  .introText {
    font-size: 24px;
    padding-right: 0;
    width: 80%;
  }

  .activities {
    width: 100%;
  }

  .activitiesBlock {
    padding-right: 0;
  }

  .activitiesMockup {
    right: -20%;
    top: 0;
    width: 45%;

  }

  .tribe {
    flex-direction: column;
  }

  .tribeMockupWrapper {
    order: 1;
    width: 100%;
  }

  .tribeMockup {
    margin-top: var(--layoutGutterXl);
    position: static
  }

  .tribeBlock {
    order: 0;
    padding-top: 0;
    width: 100%;
  }

  .subscribe {
    margin-top: 0;
  }
}
