.footer {
  color: var(--colorGrayLight);
  font-size: 18px;
  padding: var(--layoutGutterXl) 0 var(--layoutGutterXl);
  text-align: center;
}

.content {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: var(--layoutGutterLg);
}

.icon {
  font-size: 24px;
  opacity: .5;
}

.socialLink {
  color: var(--colorGrayLight);
  font-size: 24px;
  margin-left: var(--layoutGutter);
  text-decoration: none;
  transition: opacity .3s ease-in-out;
}

.socialLink:hover {
  opacity: .5;
}
