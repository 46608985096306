.input {
  font-size: 16px;
  width: 100%;
  padding: 0 var(--layoutGutterSm);
}

.labelNative {
  color: var(--colorGrayLight);
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}

.inputNative {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--colorWhite);
  border: 1px solid var(--colorWhite);
  border-radius: 15px;
  display: block;
  font-family: var(--fontBody);
  font-size: 16px;
  height: 40px;
  margin-top: var(--layoutGutterXs);
  outline: 0;
  padding: 0 var(--layoutGutter);
  transition: border-color .3s ease-in-out, opacity .3s ease-in-out;
  width: 100%;
}

.inputNative:disabled {
  opacity: .3;
}

.inputNative:focus {
  border-color: var(--colorGrayLight);
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  opacity: .3;
}
::-moz-placeholder { /* Firefox 19+ */
  opacity: .3;
}
:-ms-input-placeholder { /* IE 10+ */
  opacity: .3;
}
:-moz-placeholder { /* Firefox 18- */
  opacity: .3;
}
