.button {
  align-items: center;
  border: 0;
  border-radius: 15px;
  border: 3px solid;
  display: flex;
  font-family: var(--fontBody);
  font-weight: 800;
  font-size: 14px;
  height: 40px;
  letter-spacing: 1px;
  outline: 0;
  padding: 0 var(--layoutGutter);
  text-transform: uppercase;
  transition: opacity .3s ease-in-out;
}

.button:hover {
  opacity: .5;
}

.button:disabled {
  opacity: .3;
}

.color-blue {
  background-color: var(--colorBlue);
  border-color: var(--colorBlue);
  color: var(--colorWhite);
}

.color-white {
  background-color: var(--colorWhite);
  border-color: var(--colorWhite);
  color: var(--colorBlueDark);
}

.icon {
  font-size: 18px;
  margin-left: var(--layoutGutterSm);
}

.buttonCentered {
  margin: 0 auto;
}
