@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?8ec40n');
  src:  url('../fonts/icomoon/icomoon.eot?8ec40n#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?8ec40n') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?8ec40n') format('woff'),
    url('../fonts/icomoon/icomoon.svg?8ec40n#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activities:before {
  content: "\e904";
}
.icon-facebook:before {
  content: "\e905";
}
.icon-instagram:before {
  content: "\e906";
}
.icon-tribe:before {
  content: "\e907";
}
.icon-arrow-right:before {
  content: "\e900";
}
.icon-calendar:before {
  content: "\e901";
}
.icon-camera:before {
  content: "\e902";
}
.icon-wave:before {
  content: "\e903";
}
