/* Image hack */
.hero {
  height: 80vh;
  position: relative;
  overflow: hidden;
}

.huge {
  border-bottom-right-radius: 200% 50%;
  border-bottom-left-radius: 0;
  height: 100%;
  overflow: hidden;
  width: 150%;
}

.image {
  background: url('../../../img/landing/hero.jpg') no-repeat center center / cover;
  height: 100%;
  width: 66.66667%;
}

/* Styles */
.wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.container {
  height: 100%;
  position: relative;
}
.backdrop {
  height: 100%;
  width: 100%;
}

.logo {
  flex: 1;
  padding-top: var(--layoutGutterLg);
}

.subtitle {
  color: var(--colorWhite);
  font-size: 64px;
  font-weight: 300;
}

/* Content */
.content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .7s ease-in-out .5s, transform .7s ease-in-out .5s;
  transform: translateY(10px);
  width: 100%;
}

.isLoaded .content {
  opacity: 1;
  transform: translateY(0);
}

.button {
  margin-top: var(--layoutGutterSm);
  transform: translateY(10px);
  transition: transform .7s ease-in-out .5s;
}

.isLoaded .button {
  transform: translateY(0);
}

.copyright {
  bottom: var(--layoutGutterLg);
  left: 0;
  position: absolute;
}

.copyrightUrl {
  color: var(--colorWhite);
  display: flex;
  font-size: 10px;
  letter-spacing: 1px;
  text-decoration: none;
  transition: opacity .3s ease-in-out;
}

.copyrightUrlText {
  margin-left: var(--layoutGutterXs);
}

.copyrightUrl:hover {
  opacity: .5;
}

@media only screen and (max-width: 1200px) {
  .subtitle {
    font-size: 48px;
  }
}

@media only screen and (max-width: 1024px) {
  .backdrop {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .subtitle {
    font-size: 36px;
  }
}
